
import { trackEvent } from '@/services/Mixpanel';
import { Component, Vue } from 'vue-property-decorator';

@Component
export default class AddSite extends Vue {
  public numberTanks = 1;
  public search = '';
  public form = {
    customer: {
      name: '',
      firstName: '',
      lastName: ''
    },
    address: {
      streetAddress: '',
      suburb: '',
      townCity: '',
      postCode: ''
    },
    tags: {
      tag: ''
    },
    status: 'Pending'
  };
  public width = 1000;

  mounted() {
    trackEvent('User viewing add fuel site');

    this.updateWidth();
    window.addEventListener('resize', this.updateWidth);
  }

  destroyed() {
    window.removeEventListener('resize', this.updateWidth);
  }

  public updateWidth() {
    this.width = window.innerWidth;
  }

  onSubmit(event: any) {
    event.preventDefault();
    alert(JSON.stringify(this.form));
  }
  public goBack() {
    this.$bvModal
      .msgBoxConfirm(
        'Going back will lose any data that has been entered. Are you sure you want to continue?',
        { centered: true }
      )
      .then(value => {
        if (value) {
          this.$router.push('/pending-requests');
        }
      });
  }

  public addTanks() {
    if (this.numberTanks < 50) {
      this.numberTanks += 1;
    } else {
      console.log('Tank limit reached');
    }
  }

  public minusTanks() {
    this.$bvModal
      .msgBoxConfirm(
        'Deleting the last tank will clear any data entered on this particular tank. Are you sure you want to continue?',
        { centered: true }
      )
      .then(value => {
        if (value) {
          this.numberTanks -= 1;
        }
      });
  }

  public valueUpdated(searchTerm: string) {
    this.search = searchTerm;
  }
}
