
import { Component, Vue } from 'vue-property-decorator';
import { trackEvent } from '@/services/Mixpanel';
import { getObjectListItem } from '@/utils/LocalStorageUtils';
import { mixins } from 'vue-class-component';
import AppName from '@/components/mixin/AppName.vue';

@Component
export default class AddSite extends mixins(AppName) {
  public numberTanks = 1;
  public search = '';
  public form = {
    customer: {
      name: '',
      firstName: '',
      lastName: ''
    },
    address: {
      streetAddress: '',
      suburb: '',
      townCity: '',
      postCode: ''
    },
    tags: {
      tag: ''
    },
    custom: {},
    status: 'Pending'
  };
  public formFields: {
    name: string;
    type: string;
    required: boolean;
    disable: boolean;
    custom: boolean;
  }[] = [
    {
      name: 'Company / Trust / Partnership Name',
      type: 'Text entry',
      required: true,
      disable: false,
      custom: false
    },
    {
      name: 'First Name',
      type: 'Text entry',
      required: true,
      disable: false,
      custom: false
    },
    {
      name: 'Last Name',
      type: 'Text entry',
      required: true,
      disable: false,
      custom: false
    },
    {
      name: 'Street Address',
      type: 'Text entry',
      required: true,
      disable: false,
      custom: false
    },
    {
      name: 'Suburb',
      type: 'Text entry',
      required: true,
      disable: false,
      custom: false
    },
    {
      name: 'Town/City',
      type: 'Text entry',
      required: true,
      disable: false,
      custom: false
    },
    {
      name: 'Post code',
      type: 'Numeric text entry',
      required: true,
      disable: false,
      custom: false
    },
    {
      name: 'Tag',
      type: 'Tag input',
      required: false,
      disable: false,
      custom: false
    }
  ];
  public dropdownOptions = [
    'Yeet',
    "yeetn't",
    'Yeet',
    "yeetn't",
    'Yeet',
    "yeetn't",
    'Yeet',
    "yeetn't",
    'Yeet',
    "yeetn't"
  ];
  public displayDropdown = false;
  public width = 1000;
  public app = 'milk';

  onSubmit(event: any) {
    event.preventDefault();
    alert(JSON.stringify(this.form));
  }

  mounted() {
    trackEvent('User viewing add milk site');

    this.formFields = this.getFormFields();
    let custom: any = {};
    this.app = this.getAppName(false);

    this.formFields.forEach((field, index) => {
      if (index > 7) {
        const fieldKey = this.getKeyValue(field.name);
        const obj: { [k: string]: string } = { [fieldKey]: '' };
        custom = { ...this.form, custom: { ...this.form.custom, ...obj } };
        this.form = custom;
      }
    });

    this.updateWidth();
    window.addEventListener('resize', this.updateWidth);
  }

  destroyed() {
    window.removeEventListener('resize', this.updateWidth);
  }

  public updateWidth() {
    this.width = window.innerWidth;
  }

  public getFormFields() {
    const formFieldsFromItem = getObjectListItem(`${this.app}FormFields`);
    if (formFieldsFromItem && formFieldsFromItem.length != 0) {
      return formFieldsFromItem;
    } else {
      return this.formFields;
    }
  }

  public goBack() {
    this.$bvModal
      .msgBoxConfirm(
        'Going back will lose any data that has been entered. Are you sure you want to continue?',
        { centered: true }
      )
      .then(value => {
        if (value) {
          this.$router.push('/pending-requests');
        }
      });
  }

  public getKeyValue(name: string) {
    return name.replaceAll(' ', '');
  }

  public minusTanks() {
    this.$bvModal
      .msgBoxConfirm(
        'Deleting the last tank will clear any data entered on this particular tank. Are you sure you want to continue?',
        { centered: true }
      )
      .then(value => {
        if (value) {
          this.numberTanks -= 1;
        }
      });
  }

  public valueUpdated(searchTerm: string) {
    this.displayDropdown = true;
    this.search = searchTerm;
    if (searchTerm == '') {
      this.displayDropdown = false;
    }
  }
}
